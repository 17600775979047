import { DashboardGaugeIcon } from "@panwds/icons";
import { MAIN_ROUTE } from "./lib/constants";
import { getFrameworkVars, getAuthState } from "@sparky/framework";
import getDashboardsFromRegistry from "./lib/getDashboardsFromRegistry";
import { DASHBOARDS_EXT_POINT, type TDashboard } from "@ui-platform/ui-core-services-extension-points";

const ASSOCIATED_APPS = [
    "strata_insights_free",
    "strata_insights",
    "prisma_access",
    "prisma_access_onprem",
    "prisma_access_panorama",
    "cgx",
];

function hideDashboards(auth: any) {
    // disable dashboards for enterprise dlp or ng_casb only tenants
    // load dashboards tab only if associated apps are present
    // at present these are the apps which contribute to dashboards
    const instances = auth?.instances as any;
    const instance_apps = instances?.map((instance: any) => instance.app_id) || [];
    const found = ASSOCIATED_APPS.some((r) => instance_apps.indexOf(r) >= 0);
    if (!found) {
        return true;
    }
    return false;
}

function isDashboardsAvailable(obj: any) {
    //do not display dashboards in China Region
    if (getFrameworkVars()?.private_site_origin) return true; // GCS
    if (hideDashboards(obj.auth)) return false;
    if (!obj || !obj.access) return false;
    if (obj.access.hasRole("ademsupport")) return false;
    // if (access.hasRole("adem_tier_1_support")) return false

    const isSuperUser = obj.access
        .filter(
            ({ app_id }: { app_id: string }) =>
                app_id === "logging_service" || app_id === "" || ASSOCIATED_APPS.includes(app_id),
        )
        .map((r: any) => r.roleNames)
        .some((appRoleSet: string[]) =>
            appRoleSet.some((r: string) =>
                ["superuser", "super user", "superadmin", "super admin"].includes(r),
            ),
        );

    if (isSuperUser) return true;

    const isTsgBusinessAdmin = obj.access
        .filter(({ app_id }: { app_id: string }) => app_id === "logging_service" || app_id === "")
        .map((r: any) => r.roleNames)
        .every((appRoleSet: string[]) =>
            appRoleSet.some((r: string) =>
                ["business_admin", "iam_admin", "msp_iam_admin"].includes(r),
            ),
        );

    if (isTsgBusinessAdmin) return false;

    return true;
}

export default async () => {
    return async () => {
        const { user_preferences_ui, pdf_mode } = getFrameworkVars()
        const isUserPreferencesSupported = Boolean(user_preferences_ui);
        const isPDF = Boolean(pdf_mode)

        return {
            navigation: [
                {
                    key: "dashboards",
                    title: "Dashboards",
                    icon: DashboardGaugeIcon,
                    className: "parent-node",
                    contentClassName:
                        "panwds-tw3" + ((isUserPreferencesSupported || isPDF) ? "" : " sparky-dark-only"),
                    path: MAIN_ROUTE,
                    mergeNav: true,
                    expandable: false,
                    callAvail: true,
                    avail: isDashboardsAvailable,
                    component: () => import("./DashboardsCoreService"),
                },
                {
                    key: "aiops4ngfw-free",
                    expandable: false,
                    path: "/aiops-free",
                    mergeNav: true,
                    noRBACheck: true,
                    exact: false,
                    asPlaceholder: true,
                    avail: isDashboardsAvailable,
                    children: [
                        {
                            key: "dashboards-free",
                            title: "Dashboards",
                            icon: DashboardGaugeIcon,
                            className: "parent-node",
                            contentClassName:
                                "panwds-tw3" +
                                (isUserPreferencesSupported ? "" : " sparky-dark-only"),
                            path: "/aiops-free" + MAIN_ROUTE,
                            mergeNav: true,
                            expandable: false,
                            callAvail: true,
                            component: () => import("./DashboardsCoreService"),
                        },
                    ],
                },
            ],
        };
    };
};

export const exposes = {
    copilot_available_paths: {
        dashboards() {
            const dashboards = getDashboardsFromRegistry()
            return dashboards.map(({ name, path, allowed, description, widgets }) => ({
                title: name.getMessage?.() ?? name.defaultMessage,
                path,
                description,
                isAllowed: allowed,
                widgets
            }))
        }
    }
}

export const extensionPoints = [DASHBOARDS_EXT_POINT];